import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IntegrationServiceVerifyCalendarPayload } from '@app-store/slices/integrationServices/verifyCalendar'

interface TaskAction extends Action {
    payload: IntegrationServiceVerifyCalendarPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.integrationServices.verifyCalendar

function* integrationServiceVerifyCalendarSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`meeting/sync/calendar/sync/by-token/${payload.token}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* integrationVerifyCalendarServiceSaga(): any {
    yield takeLatest<TaskAction>(onRequest, integrationServiceVerifyCalendarSagaTask)
}

export default integrationVerifyCalendarServiceSaga
