import { put, takeLatest, all, take, select } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { RootState } from '@app-store/store'
import routes from '@utils/constants/routes'
import { history } from '@components/App'
import { useUser } from '../../../hooks'
import forOwn from 'lodash/forOwn'
import { setEventCookie } from '@features/Auth'
import { COOKIE_KEYS, getCookie } from '@features/General'
import { ListTaskAction } from '@typings/requestPayloads'

const onboarding = rootActions.companies.onboardingExhibitor
const { onRequest: getPreSignedUploadURL } = rootActions.contacts.getPreSignedUploadURL
const { onRequest: fileUpload } = rootActions.contacts.fileUpload
const { onRequest: getContact } = rootActions.companies.contactProfile

function* saveSaga({ payload }) {
    try {
        yield put(getContact({}))
        yield take('hub/companies/contact-profile/onSuccess')
        const eventId = getCookie(COOKIE_KEYS.ALA_EVENT)
        const response = yield axios.post(
            `/meeting/unavailable`,
            { ...payload.unavailableCalendar },
            {
                headers: {
                    'event-id': payload.eventID
                }
            }
        )

        if (response) {
            if (payload.eventID && eventId !== payload.eventID) {
                // yield put(rootActions.organizer.eventDetails.view.setFinishModal(true))
                setEventCookie(payload.eventID)
            }
            if (typeof payload?.sagaCB?.onSuccess === 'function') {
                payload?.sagaCB?.onSuccess()
            }
            history.push(routes.organizer._)
            yield put(onboarding.onSuccess(response))
        }
    } catch (error) {
        yield put(onboarding.onFailure(error))
    }
}

function* uploadImage({ payload }) {
    try {
        yield put(getPreSignedUploadURL())
        yield take('hub/contacts/get-presigned-upload-URL/onSuccess')
        const { file } = yield select((store: RootState) => store.contacts.getPreSignedUploadURL.data)
        yield put(fileUpload(payload.image[0]))
        yield take('hub/contacts/file-upload/onSuccess')
        yield put(
            rootActions.companies.onboardingExhibitor.set({
                type: payload.name,
                value: file
            })
        )
    } catch (e) {
        throw new Error(e)
    }
}

function* saveITP({ payload }) {
    const { companyId } = useUser()
    const data = yield select((store: RootState) => store.companies.onboardingExhibitor)
    const { data: settingsData } = yield select((state: RootState) => state.eventSetting.oneSetting)
    let countries = []
    const { selectedArea, selectedCountries } = data.locationItp
    forOwn(selectedCountries, value => {
        countries = [...countries, ...value]
    })
    try {
        const response = yield axios.post(
            `/companies/itps`,
            {
                title: data.title,
                ownerCompany: companyId,
                locations: [...selectedArea],
                countries: [...countries],
                companySize: [...data.companySize],
                type: [...data.type],
                itpType: settingsData?.match?.itpType || data.itpType[0],
                positions: [...data.positions],
                jobDepartments: [...data.jobDepartments],
                seniorityLevels: [...data.seniorityLevels],
                fundingStage: [...data.fundingStage],
                sectors: [...data.sectors],
                tags: data.tags.map(item => item._id || item),
                clientWebsiteExample: data.clientWebsiteExample,
                competitorWebsiteExample: data.competitorWebsiteExample
            },
            {
                headers: {
                    'event-id': data.event._id
                }
            }
        )

        if (response) {
            yield put(onboarding.onSaveITPSucces())
            yield put(rootActions.users.profile.onRequest({}))
            if (typeof payload?.sagaCB?.onSuccess === 'function') {
                payload.sagaCB.onSuccess(response)
            }
            yield take('hub/user/profile/onSuccess')
            history.push(routes.exhibitor.onboarding.categories._)
        }
    } catch (error) {
        yield put(onboarding.onSaveITPFailure())
    }
}

const { onRequest: getCompany } = rootActions.companies.oneCompany

function* getProfile() {
    const { user } = useUser()
    const data = yield select((store: RootState) => store.companies.onboardingExhibitor)
    const companyId = user.eventInfos.find(x => x.eventId === data.event._id)?.companyId
    try {
        yield put(getContact({ eventId: data.event._id }))
        yield take('hub/companies/contact-profile/onSuccess')
        const contact = yield select((store: RootState) => store.companies.contactProfile.data)
        const profile = {
            profileImage: contact.avatar,
            firstname: contact.firstname,
            lastname: contact.lastname,
            linkedin: contact.linkedin,
            twitter: contact.twitter,
            company: contact.company,
            email: contact.email,
            conversationStarter: contact.conversationStarter,
            description: contact.description,
            mobile: contact.mobile
        }
        let company = {}
        if (data.isAdmin && companyId) {
            yield put(getCompany({ companyId: companyId, eventId: data.event._id }))
            yield take('hub/companies/one-company/onSuccess')
            const oneCompany = yield select((store: RootState) => store.companies.oneCompany.data)
            company = {
                companyImage: null,
                company_name: oneCompany.name,
                company_website: oneCompany.website,
                company_tel: oneCompany.tel,
                company_email: oneCompany.email,
                company_country: oneCompany.country,
                company_city: oneCompany.city?._id || oneCompany.city,
                company_address: oneCompany.address,
                company_keywords1:
                    Array.isArray(oneCompany.keyIndicators) && oneCompany.keyIndicators[0]
                        ? oneCompany.keyIndicators[0]
                        : '',
                company_keywords2:
                    Array.isArray(oneCompany.keyIndicators) && oneCompany.keyIndicators[1]
                        ? oneCompany.keyIndicators[1]
                        : '',
                company_keywords3:
                    Array.isArray(oneCompany.keyIndicators) && oneCompany.keyIndicators[2]
                        ? oneCompany.keyIndicators[2]
                        : '',
                company_brief: oneCompany.brief
            }
        }
        if (contact) {
            yield put(onboarding.onGetProfileSucces(Object.assign(profile, company)))
        }
    } catch (error) {
        yield put(onboarding.onGetProfileFailure())
    }
}

function* listSaga(): any {
    yield all([
        takeLatest<ListTaskAction>(onboarding.onRequest, saveSaga),
        takeLatest<ListTaskAction>(onboarding.onSaveITP, saveITP),
        takeLatest<ListTaskAction>(onboarding.onGetProfile, getProfile),
        takeLatest<ListTaskAction>(onboarding.onUploadImage, uploadImage)
    ])
}

export default listSaga
