import listSaga from './listSaga'
import integrationServiceEmailSaga from './emailSaga'
import integrationServiceVerifyEmailSaga from './verifyEmailSaga'
import integrationServiceCalendarSaga from './calendarSaga'
import integrationVerifyCalendarServiceSaga from './verifyCalendarSaga'

const integrationServices = [
    listSaga(),
    integrationServiceEmailSaga(),
    integrationServiceVerifyEmailSaga(),
    integrationServiceCalendarSaga(),
    integrationVerifyCalendarServiceSaga()
]

export default integrationServices
