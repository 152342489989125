import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
import { INVITATION_TYPE } from './integrateEmail'

export enum CALENDAR_TYPE {
    GOOGLE = 'Google',
    OUTLOOK = 'Outlook',
    CALENDLY = 'Calendly'
}
export interface ICalendarIntegratePayload extends ISagaCb {
    data: {
        contact: string
        invitationType: INVITATION_TYPE
        type?: CALENDAR_TYPE
    }
}

export interface IState {
    status: string
    success: boolean
    result: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    success: true,
    result: {},
    errors: null
}

const integrateCalendarSlice = createSlice({
    name: 'send-email/integrate-calendar',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICalendarIntegratePayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.result = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const integrateCalendarActions = integrateCalendarSlice.actions
export default integrateCalendarSlice.reducer
