import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
const matchListsSlice = createSlice({
    name: 'ui/match-list',
    initialState: {
        ...listUIInitialState,
        editMatchListModal: {
            isOpen: false,
            id: '',
            title: ''
        },
        duplicateMatchListModal: {
            isOpen: false,
            matchedCompanies: [],
            businessPotential: '',
            title: ''
        },
        populates: [{ path: 'ownerCompany', select: '_id name' }]
    },
    reducers: {
        ...listUIReducers,
        setEditModalInfo(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.editMatchListModal[item] = action.payload[item]
            }
        },
        setDuplicateModalInfo(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.duplicateMatchListModal[item] = action.payload[item]
            }
        }
    }
})

export const matchListsActions = matchListsSlice.actions
export default matchListsSlice.reducer
