import store from 'store'
import { COOKIE_KEYS, getCookie, LOCALSTORAGE_KEYS } from '@features/General'
interface IUser {
    cognitoUserIds: string[]
    createdAt: string
    email: string
    eventInfos: any[]
    location: any
    company?: string
    role?: any
    status: string
    updatedAt: string
    __v: number
    _id: string
    firstname?: string
    lastname?: string
    avatar?: string
}

function useUser() {
    const user: IUser = store.get(LOCALSTORAGE_KEYS.USERINFO)
    const eventId = getCookie(COOKIE_KEYS.ALA_EVENT)
    const companyId = user?.eventInfos.find(x => x.eventId === eventId)?.companyId || undefined
    const appDirection = getCookie(COOKIE_KEYS.ALA_DIRECTION) || 'ltr'
    const events = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.EVENT_LIST))
    const companyName = events?.find(item => item._id === eventId)?.companyId?.name || ''
    const userProfile: any = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.USERINFO))
    const selectedEvent = eventId ? userProfile?.eventInfos.find(item => item.eventId === eventId) : null
    const contactInfo: any = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.CONTACT_INFO))

    return {
        user,
        eventId,
        companyId,
        contactId: selectedEvent?.contactId,
        companyName: contactInfo?.companyName || companyName,
        appDirection,
        userEmail: user?.email,
        contactName: `${contactInfo?.firstname || ''} ${contactInfo?.lastname || ''}`.trim(),
        contactFirstName: contactInfo?.firstname
    }
}

export default useUser
