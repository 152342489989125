import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEventProfilesByTokenPayload } from '@app-store/slices/eventSettings/eventProfilesByToken'

interface TaskAction extends Action {
    payload: IEventProfilesByTokenPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.eventSetting.eventProfilesByToken

function* eventProfilesByTokenTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/event/event-profiles/by-token?token=${payload.token}`)
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            yield put(onFailure(response.result))
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* eventProfilesByToken(): any {
    yield takeLatest(onRequest, eventProfilesByTokenTask)
}

export default eventProfilesByToken
