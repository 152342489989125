import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IntegrationServiceVerifyEmailPayload } from '@app-store/slices/integrationServices/verifyEmail'

interface TaskAction extends Action {
    payload: IntegrationServiceVerifyEmailPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.integrationServices.verifyEmail

function* integrationServiceVerifyEmailSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`notifications/email-credentials/by-token/${payload.token}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* integrationVerifyEmailServiceSaga(): any {
    yield takeLatest<TaskAction>(onRequest, integrationServiceVerifyEmailSagaTask)
}

export default integrationVerifyEmailServiceSaga
