import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.companies.preferenceCustomizationList

function* preferenceCustomizationListSagaTask({ payload }: ListTaskAction) {
    try {
        const params = yield getParams(payload)
        const response = yield axios.get(`/companies/preference-customization`, {
            params
        })

        if (response.success) {
            yield put(onSuccess({ noSaveInStore: payload.noSaveInStore, ...response.result }))
            yield payload.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* preferenceCustomizationListSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, preferenceCustomizationListSagaTask)
}

export default preferenceCustomizationListSaga
