import { createSlice } from '@reduxjs/toolkit'
import { toggleValueInArray } from '@features/General/handlers'
const dashboardSlice = createSlice({
    name: 'ui/dashboard',
    initialState: {
        hiddenWidgets: []
    },
    reducers: {
        setHiddenWidget(state, action) {
            state.hiddenWidgets = action.payload
        },
        toggleHiddenWidget(state, action) {
            state.hiddenWidgets = toggleValueInArray(state.hiddenWidgets, action.payload)
        }
    }
})

export const dashboardActions = dashboardSlice.actions
export default dashboardSlice.reducer
