import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditPreferenceCustomizationPayload } from '@store/slices/companies/editPreferenceCustomization'

interface TaskAction extends Action {
    payload: IEditPreferenceCustomizationPayload
}

const { onSuccess, onRequest, onFailure } = rootActions.companies.editPreferenceCustomization

function* editPreferenceCustomizationSagaTask({ payload }) {
    try {
        const response = yield axios.put(`/companies/preference-customization/${payload.id}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (typeof payload?.sagaCB?.onSuccess === 'function') {
                payload.sagaCB.onSuccess(response.result)
            }
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editPreferenceCustomizationSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editPreferenceCustomizationSagaTask)
}

export default editPreferenceCustomizationSaga
