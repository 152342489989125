import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { makeRequestQuery } from '@utils/helpers/queryHelpers'
import { ListTaskAction } from '@typings/requestPayloads'
import { contactListQueryMap } from '@utils/QueryMaps'

const { onRequest, onSuccess, onFailure } = rootActions.wishLists.list

function* listWishListsSagaTask({ payload }: ListTaskAction) {
    try {
        const query: any = payload.query || makeRequestQuery(contactListQueryMap)
        const params = {
            populates: payload.populates || [{ path: 'wishedCompanies' }],
            ...payload.pagination,
            query,
            needPopulate: true
        }

        const response = yield axios.get(`/companies/wish-lists`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
        }
        if (payload?.sagaCB) {
            payload?.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listWishListsSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, listWishListsSagaTask)
}

export default listWishListsSaga
