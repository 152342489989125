import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IntegrationCalendarPayload } from '@app-store/slices/integrationServices/calendar'

interface TaskAction extends Action {
    payload: IntegrationCalendarPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.integrationServices.calendar

function* integrationServiceCalendarSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(
            `meeting/sync/calendar/auth-url/by-token/${payload.token}?sourceType=${payload.type}`
        )
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* integrationServiceCalendarSaga(): any {
    yield takeLatest<TaskAction>(onRequest, integrationServiceCalendarSagaTask)
}

export default integrationServiceCalendarSaga
