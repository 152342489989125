import sendEmailReducer, { sendEmailActions } from '@app-store/slices/emails/sendEmail'
import sendInvitationReducer, { sendInvitationActions } from '@store/slices/emails/sendInvitation'
import { combineReducers } from 'redux'
import integrateCalendarReducer, { integrateCalendarActions } from './integrateCalendar'
import integrateEmailReducer, { integrateEmailActions } from './integrateEmail'

export const emailsReducer = combineReducers({
    sendEmail: sendEmailReducer,
    sendInvitation: sendInvitationReducer,
    integrateEmail: integrateEmailReducer,
    integrateCalendar: integrateCalendarReducer
})

export const emailsActions = {
    sendEmail: sendEmailActions,
    sendInvitation: sendInvitationActions,
    integrateEmail: integrateEmailActions,
    integrateCalendar: integrateCalendarActions
}
