import createSaga from './createSaga'
import listSaga from './listSaga'
import oneSettingSaga from './oneSettingSaga'
import editGlobalRenamingSaga from './editGlobalRenamingSaga'
import editMatchSaga from './editMatchSaga'
import editUserInvitationSaga from './editUserInvitationSaga'
import editNotificationSaga from './editNotificationSaga'
import editVisibilitySaga from './editVisibilitySaga'
import editMenuVisibilitySaga from './editMenuVisibility'
import editGroupVisibilityByIdSaga from './editGroupVisibilityByIdSaga'
import editDefaultVisibilitySaga from './editDefaultVisibilitySaga'
import createVisibilitySaga from './createVisibilitySaga'
import deleteVisibilitySaga from './deleteVisibilitySaga'
import oneSectionSettingSaga from './oneSectionSettingSaga'
import editMeetingSaga from './editMeetingSaga'
import activeFeaturesSaga from './activeFeaturesSaga'
import eventProfilesSaga from './eventProfilesSaga'
import createEventProfilesSaga from './createEventProfilesSaga'
import editEventProfilesSaga from './editEventProfilesSaga'
import eventProfilesByToken from './eventProfileByTokenSaga'

const eventSettingSaga = [
    createSaga(),
    listSaga(),
    oneSettingSaga(),
    editGlobalRenamingSaga(),
    editMatchSaga(),
    editUserInvitationSaga(),
    editNotificationSaga(),
    editVisibilitySaga(),
    editGroupVisibilityByIdSaga(),
    editDefaultVisibilitySaga(),
    createVisibilitySaga(),
    deleteVisibilitySaga(),
    editMenuVisibilitySaga(),
    oneSectionSettingSaga(),
    editMeetingSaga(),
    activeFeaturesSaga(),
    eventProfilesSaga(),
    createEventProfilesSaga(),
    editEventProfilesSaga(),
    eventProfilesByToken()
]

export default eventSettingSaga
