import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IntegrationServiceVerifyCalendarPayload extends ISagaCb {
    token: string
    data: {
        code: string
        sourceType: 'Google' | 'Outlook' | 'Calendly'
    }
}

export interface IState {
    status: string
    success: boolean
    result: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    success: true,
    result: {},
    errors: null
}

const integrationServiceVerifyCalendarSlice = createSlice({
    name: 'integration_service/verify_calendar',
    initialState: initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IntegrationServiceVerifyCalendarPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.result = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const integrationServiceVerifyCalendarSliceActions = integrationServiceVerifyCalendarSlice.actions
export default integrationServiceVerifyCalendarSlice.reducer
