import { auditTrailsActions, auditTrailsReducers } from '@app-store/slices/auditTrails'
import { authActions, authReducer } from '@app-store/slices/auth'
import { badgesActions, badgesReducers } from '@app-store/slices/badges'
import { calendarActions, calendarReducers } from '@app-store/slices/calendar'
import { callLogActions, callLogReducers } from '@app-store/slices/callLog'
import { campaignsActions, campaignsReducer } from '@app-store/slices/campaigns'
import { categoriesActions, categoriesReducer } from '@app-store/slices/categories'
import { chatActions, chatReducer } from '@app-store/slices/chat'
import { chatDetailsActions, chatDetailsReducer } from '@app-store/slices/chatDetails'
import { citiesActions, citiesReducer } from '@app-store/slices/cities'
import { companiesActions, companiesReducer } from '@app-store/slices/companies'
import { companyStatisticsActions, companyStatisticsReducer } from '@app-store/slices/companyStatistics'
import { companySubscriptionActions, companySubscriptionReducers } from '@app-store/slices/companySubscriptions'
import { conferencesActions, conferencesReducer } from '@app-store/slices/conferences'
import { contactsActions, contactsReducer } from '@app-store/slices/contacts'
import { countriesActions, countriesReducer } from '@app-store/slices/countries'
import { crmActions, crmReducer } from '@app-store/slices/crm'
import { dataImportActions, dataImportReducer } from '@app-store/slices/dataImport'
import { demoActions, demoReducers } from '@app-store/slices/demos'
import { editListActions, editListReducers } from '@app-store/slices/editList'
import { emailCredentialActions, emailCredentialReducer } from '@app-store/slices/emailCredential'
import { emailsActions, emailsReducer } from '@app-store/slices/emails'
import { eventTypeActions, eventTypeReducer } from '@app-store/slices/event-type'
import { eventsActions, eventsReducer } from '@app-store/slices/events'
import { eventSettingActions, eventSettingReducer } from '@app-store/slices/eventSettings'
import { eventSubscriptionActions, eventSubscriptionReducers } from '@app-store/slices/eventSubscriptions'
import { eventTopicsActions, eventTopicsReducer } from '@app-store/slices/eventTopics'
import { exhibitorActions, exhibitorReducer } from '@app-store/slices/exhibitor'
import { featureActions, featureReducer } from '@app-store/slices/feature'
import { groupActions, groupReducer } from '@app-store/slices/group'
import { hallActions, hallReducer } from '@app-store/slices/hall'
import { integrationServicesActions, integrationServicesReducer } from '@app-store/slices/integrationServices'
import { labelTagActions, labelTagReducers } from '@app-store/slices/labelTag'
import { linkedInAccountActions, linkedInAccountReducers } from '@app-store/slices/linkedInAccount'
import { localizationActions, localizationReducers } from '@app-store/slices/localization'
import { matchListGroupsActions, matchListGroupsReducer } from '@app-store/slices/matchListGroups'
import { matchListsActions, matchListsReducer } from '@app-store/slices/matchLists'
import { matchEngineActions, matchEngineReducer } from '@app-store/slices/match_engine'
import { meetingAgendaActions, meetingAgendaReducer } from '@app-store/slices/meetingAgenda'
import { meetingRequestsActions, meetingRequestsReducer } from '@app-store/slices/meetingRequests'
import { meetingsActions, meetingsReducer } from '@app-store/slices/meetings'
import { meetingStatisticsActions, meetingStatisticsReducer } from '@app-store/slices/meetingStatistics'
import { moduleActions, moduleReducers } from '@app-store/slices/module'
import { momentumActions, momentumReducer } from '@app-store/slices/momentum'
import { newFeatureActions, newFeatureReducers } from '@app-store/slices/newFeature'
import { notificationActions, notificationReducer } from '@app-store/slices/notifications'
import onboardingTemplatesReducer, { onboardingTemplatesActions } from '@app-store/slices/onboardingTemplates'
import { onbordingOrganizerActions, onbordingOrganizerReducer } from '@app-store/slices/onbordingOrganizer'
import { organizerActions, organizerReducer } from '@app-store/slices/organizer'
import { productsActions, productsReducer } from '@app-store/slices/products'
import { propertiesActions, propertiesReducer } from '@app-store/slices/properties'
import { qrActions, qrReducers } from '@app-store/slices/qr'
import { quotationsActions, quotationsReducer } from '@app-store/slices/quotations'
import { reportingActions, reportingReducers } from '@app-store/slices/reporting'
import { rfqsActions, rfqsReducer } from '@app-store/slices/rfqs'
import { rolesPermissonsActions, rolesPermissonsReducer } from '@app-store/slices/rolesPermissions'
import { scansActions, scansReducer } from '@app-store/slices/scans'
import { servicesActions, servicesReducer } from '@app-store/slices/services'
import { sessionsActions, sessionsReducer } from '@app-store/slices/sessions'
import { sharedListsActions, sharedListsReducer } from '@app-store/slices/sharedLists'
import { sourceCategoriesActions, sourceCategoriesReducer } from '@app-store/slices/sourceCategories'
import { speakersActions, speakersReducer } from '@app-store/slices/speakers'
import { subscriptionOrderActions, subscriptionOrderReducers } from '@app-store/slices/subscriptionOrder'
import { subscriptionPaymentActions, subscriptionPaymentReducers } from '@app-store/slices/subscriptionPayment'
import { subscriptionActions, subscriptionReducers } from '@app-store/slices/subscriptions'
import { supportActions, supportReducer } from '@app-store/slices/support'
import { surveyActions, surveyReducer } from '@app-store/slices/survey'
import { tableActions, tableReducer } from '@app-store/slices/table'
import { tagsActions, tagsReducer } from '@app-store/slices/tags'
import { templateActions, templateReducers } from '@app-store/slices/templates'
import { tourActions, tourReducers } from '@app-store/slices/tours'
import { transactionActions, transactionReducers } from '@app-store/slices/transactions'
import { uiActions, uiReducer } from '@app-store/slices/ui'
import { userActions, userReducer } from '@app-store/slices/users'
import { userStatisticsActions, userStatisticsReducers } from '@app-store/slices/userStatistics'
import { wishListsActions, wishListsReducer } from '@app-store/slices/wishLists'
import { perfectMatchActions, perfectMatchReducers } from '@store/slices/perfectMatch'
import { unsubscribeActions, unsubscribeReducers } from '@store/slices/unSubscribe'
import { combineReducers } from 'redux'

export const rootReducer = combineReducers({
    auth: authReducer,
    organizer: organizerReducer,
    exhibitor: exhibitorReducer,
    matchEngine: matchEngineReducer,
    emailCredential: emailCredentialReducer,
    campaigns: campaignsReducer,
    companies: companiesReducer,
    chatDetails: chatDetailsReducer,
    chat: chatReducer,
    contacts: contactsReducer,
    group: groupReducer,
    users: userReducer,
    emails: emailsReducer,
    meetingAgenda: meetingAgendaReducer,
    meetings: meetingsReducer,
    hall: hallReducer,
    eventSetting: eventSettingReducer,
    ui: uiReducer,
    countries: countriesReducer,
    cities: citiesReducer,
    events: eventsReducer,
    categories: categoriesReducer,
    eventType: eventTypeReducer,
    table: tableReducer,
    properties: propertiesReducer,
    matchLists: matchListsReducer,
    matchListGroups: matchListGroupsReducer,
    sharedLists: sharedListsReducer,
    sourceCategories: sourceCategoriesReducer,
    dataImport: dataImportReducer,
    onboardingTemplates: onboardingTemplatesReducer,
    onbordingOrganizer: onbordingOrganizerReducer,
    meetingRequests: meetingRequestsReducer,
    eventTopics: eventTopicsReducer,
    conferences: conferencesReducer,
    sessions: sessionsReducer,
    speakers: speakersReducer,
    tags: tagsReducer,
    wishLists: wishListsReducer,
    editList: editListReducers,
    rolesPermissions: rolesPermissonsReducer,
    companyStatistics: companyStatisticsReducer,
    meetingStatistics: meetingStatisticsReducer,
    products: productsReducer,
    services: servicesReducer,
    momentum: momentumReducer,
    notification: notificationReducer,
    scans: scansReducer,
    survey: surveyReducer,
    integrationServices: integrationServicesReducer,
    crm: crmReducer,
    templates: templateReducers,
    localization: localizationReducers,
    subscriptions: subscriptionReducers,
    companySubscriptions: companySubscriptionReducers,
    orders: subscriptionOrderReducers,
    subscriptionPayment: subscriptionPaymentReducers,
    support: supportReducer,
    reporting: reportingReducers,
    badges: badgesReducers,
    rfqs: rfqsReducer,
    quotations: quotationsReducer,
    tours: tourReducers,
    demos: demoReducers,
    transactions: transactionReducers,
    linkedInAccounts: linkedInAccountReducers,
    auditTrails: auditTrailsReducers,
    callLog: callLogReducers,
    calendar: calendarReducers,
    newFeature: newFeatureReducers,
    module: moduleReducers,
    labelTag: labelTagReducers,
    qr: qrReducers,
    userStatistics: userStatisticsReducers,
    feature: featureReducer,
    eventSubscriptions: eventSubscriptionReducers,
    unSubscribe: unsubscribeReducers,
    perfectMatch: perfectMatchReducers
})

export const rootActions = {
    auth: authActions,
    organizer: organizerActions,
    exhibitor: exhibitorActions,
    matchEngine: matchEngineActions,
    emailCredential: emailCredentialActions,
    campaigns: campaignsActions,
    companies: companiesActions,
    chatDetails: chatDetailsActions,
    chat: chatActions,
    contacts: contactsActions,
    group: groupActions,
    users: userActions,
    emails: emailsActions,
    meetingAgenda: meetingAgendaActions,
    meetings: meetingsActions,
    hall: hallActions,
    table: tableActions,
    eventSetting: eventSettingActions,
    ui: uiActions,
    rolesPermissions: rolesPermissonsActions,
    countries: countriesActions,
    cities: citiesActions,
    events: eventsActions,
    categories: categoriesActions,
    eventType: eventTypeActions,
    properties: propertiesActions,
    matchLists: matchListsActions,
    matchListGroups: matchListGroupsActions,
    sharedLists: sharedListsActions,
    sourceCategories: sourceCategoriesActions,
    dataImport: dataImportActions,
    onboardingTemplates: onboardingTemplatesActions,
    onbordingOrganizer: onbordingOrganizerActions,
    meetingRequests: meetingRequestsActions,
    eventTopics: eventTopicsActions,
    conferences: conferencesActions,
    sessions: sessionsActions,
    speakers: speakersActions,
    tags: tagsActions,
    wishLists: wishListsActions,
    editList: editListActions,
    companyStatistics: companyStatisticsActions,
    meetingStatistics: meetingStatisticsActions,
    products: productsActions,
    services: servicesActions,
    momentum: momentumActions,
    notification: notificationActions,
    scans: scansActions,
    survey: surveyActions,
    integrationServices: integrationServicesActions,
    crm: crmActions,
    templates: templateActions,
    localization: localizationActions,
    subscriptions: subscriptionActions,
    orders: subscriptionOrderActions,
    companySubscriptions: companySubscriptionActions,
    subscriptionPayment: subscriptionPaymentActions,
    support: supportActions,
    reporting: reportingActions,
    badges: badgesActions,
    rfqs: rfqsActions,
    quotations: quotationsActions,
    tours: tourActions,
    demos: demoActions,
    transactions: transactionActions,
    linkedInAccounts: linkedInAccountActions,
    auditTrails: auditTrailsActions,
    callLog: callLogActions,
    calendar: calendarActions,
    newFeature: newFeatureActions,
    module: moduleActions,
    labelTag: labelTagActions,
    qr: qrActions,
    userStatistics: userStatisticsActions,
    feature: featureActions,
    eventSubscriptions: eventSubscriptionActions,
    unSubscribe: unsubscribeActions,
    perfectMatch: perfectMatchActions
}
