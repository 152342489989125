import { combineReducers } from 'redux'
import listIntegrationServiceSlice, { listIntegrationServiceActions } from './list'
import integrationServiceEmailSlice, { integrationServiceEmailSliceActions } from './email'
import integrationServiceVerifyEmailSlice, { integrationServiceVerifyEmailSliceActions } from './verifyEmail'
import integrationServiceCalendarSlice, { integrationServiceCalendarSliceActions } from './calendar'
import integrationServiceVerifyCalendarSlice, { integrationServiceVerifyCalendarSliceActions } from './verifyCalendar'

export const integrationServicesReducer = combineReducers({
    list: listIntegrationServiceSlice,
    email: integrationServiceEmailSlice,
    verifyEmail: integrationServiceVerifyEmailSlice,
    calendar: integrationServiceCalendarSlice,
    verifyCalendar: integrationServiceVerifyCalendarSlice
})

export const integrationServicesActions = {
    list: listIntegrationServiceActions,
    email: integrationServiceEmailSliceActions,
    verifyEmail: integrationServiceVerifyEmailSliceActions,
    calendar: integrationServiceCalendarSliceActions,
    verifyCalendar: integrationServiceVerifyCalendarSliceActions
}
