import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'

const preferenceCustomizationSlice = createSlice({
    name: 'ui/preference-customization',
    initialState: listUIInitialState,
    reducers: listUIReducers
})

export const preferenceCustomizationActions = preferenceCustomizationSlice.actions
export default preferenceCustomizationSlice.reducer
