import { COOKIE_KEYS, LOCALSTORAGE_KEYS } from '@features/General'
import { history } from '@components/App'
import request from '@utils/request'
import { rootActions } from '@store/slices'
import store from '@app-store/store'
import { blackSubdomainList } from '../constants'
import routes from '@utils/constants/routes'

export const setUserAuthCookie = (token: string, path: 'secure;' | '') => {
    const isRemember = `max-age=${1 * 365 * 24 * 60 * 60};`
    document.cookie = `${COOKIE_KEYS.ALA_TOKEN}=${encodeURI(token)};${isRemember};path=/;${path}`
}

export const setRefreshTokenCookie = (token: string, path: 'secure;' | '') => {
    const isRemember = `max-age=${1 * 365 * 24 * 60 * 60};`
    document.cookie = `${COOKIE_KEYS.ALA_REFRESH}=${encodeURI(token)};${isRemember};path=/;${path}`
}

export const setUserRoleCookie = (role, type) => {
    const isRemember = `max-age=${1 * 365 * 24 * 60 * 60};`
    document.cookie = `${COOKIE_KEYS.ALA_USER_ROLE}=${encodeURI(role)};${isRemember};path=/`
    document.cookie = `${COOKIE_KEYS.ALA_USER_TYPE}=${encodeURI(type)};${isRemember};path=/`
}

export const setEventCookie = event => {
    request.defaults.headers.common = {
        ...request.defaults.headers.common
        // 'event-id': event
    }
    const isRemember = `max-age=${1 * 365 * 24 * 60 * 60};`
    document.cookie = `${COOKIE_KEYS.ALA_EVENT}=${encodeURI(event)};${isRemember};path=/`
    document.cookie = `${COOKIE_KEYS.NON_LOGGED_IN_USER_ID}=;max-age=0;path=/`
}

export const removeUserAuthCookie = (isExpiredToken = false) => {
    if (!isExpiredToken) {
        delete request.defaults.headers?.common?.['event-id']
    }
    document.cookie = `${COOKIE_KEYS.ALA_TOKEN}=;max-age=0;path=/`
    document.cookie = `${COOKIE_KEYS.ALA_REFRESH}=;max-age=0;path=/`
    document.cookie = `${COOKIE_KEYS.ALA_USER_ROLE}=;max-age=0;path=/`
    document.cookie = `${COOKIE_KEYS.ALA_USER_TYPE}=;max-age=0;path=/`
    const loginUiValues: any = { redirectRoute: '', loggingIn: false }
    if (!isExpiredToken) {
        document.cookie = `${COOKIE_KEYS.ALA_EVENT}=;max-age=0;path=/`
        history.replace(routes.login._)
        localStorage.removeItem(LOCALSTORAGE_KEYS.USERINFO)
        localStorage.removeItem(LOCALSTORAGE_KEYS.CONTACT_INFO)
    } else {
        loginUiValues.loginModalVisible = true
    }
    store.dispatch(rootActions.ui.authSlider.setLoggedInInfo(loginUiValues))
}

export const getSubDomain = (considerAsMeetingHub = false) => {
    // 'https://monshaat.aladdinb2b.com' - This is for testing purpose.
    // window.location.href                 - Put it in when live.
    if (considerAsMeetingHub) {
        return process.env.NODE_ENV === 'development' ? 'devhub' : 'hub'
    }
    const url = window.location.href
    // const url = 'https://hub.aladdinb2b.com/'
    // const url = 'https://worldutilitiescongress.aladdinb2b.com'
    const [, topLevel_domain] = url.split('//', 5)
    const [subdomain] = topLevel_domain.split('.')
    return subdomain.includes('localhost:7080') ? 'localhost' : subdomain
}

export const isValidSubdomain = (considerAsMeetingHub = false) => {
    if (considerAsMeetingHub) return true
    const subdomain = getSubDomain()
    return !!subdomain && !blackSubdomainList.includes(subdomain)
}

export const existSubdomain = event => {
    return isValidSubdomain() && event?.branding
}
