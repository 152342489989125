import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export enum INVITATION_TYPE {
    COPY = 'Copy',
    SEND = 'Send'
}
export enum SERVICE_TYPE {
    GMAIL = 'Gmail',
    OUTLOOK = 'Outlook'
}

export enum REDUCER_SERVICE {
    EMAIL = 'integrateEmail',
    CALENDAR = 'integrateCalendar'
}

export interface IEmailIntegratePayload extends ISagaCb {
    data: {
        contact: string
        invitationType: INVITATION_TYPE
        type?: SERVICE_TYPE
    }
}

export interface IState {
    status: string
    success: boolean
    result: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    success: true,
    result: {},
    errors: null
}

const integrateEmailSlice = createSlice({
    name: 'send-email/integrate-email',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEmailIntegratePayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.result = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const integrateEmailActions = integrateEmailSlice.actions
export default integrateEmailSlice.reducer
