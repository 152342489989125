import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ISubscriptionDataItems {
    subscriptionId?: string
    isMeetingHub?: boolean
    isTrial?: boolean
    isFree?: boolean
    isAnnual?: boolean
    userOption?: string
    setterOption?: string
    customizePlanModalVisible?: boolean
    payment?: {
        cardNumber?: string
        expireDate?: string
        cvv?: string
        cardHolderName?: string
        companyName?: string
        billingEmail?: string
        billingAddress?: string
        city?: string
        zipCode?: string
        country?: string
    }
    pronoCode?: string
}

export interface ISubscriptionUIState {
    data: ISubscriptionDataItems
}

const initialState: ISubscriptionUIState = {
    data: {
        subscriptionId: '',
        isMeetingHub: false,
        isAnnual: false,
        userOption: '',
        setterOption: '',
        isTrial: false,
        isFree: false,
        customizePlanModalVisible: false,
        payment: {
            cardNumber: '',
            expireDate: '',
            cvv: '',
            cardHolderName: '',
            companyName: '',
            billingEmail: '',
            billingAddress: '',
            city: '',
            zipCode: '',
            country: ''
        },
        pronoCode: ''
    }
}
const subscriptionSlice = createSlice({
    name: 'ui/subscription',
    initialState,
    reducers: {
        addItems(state, action: PayloadAction<ISubscriptionDataItems>) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.data[item] = action.payload[item]
            }
        }
    }
})

export const subscriptionActions = subscriptionSlice.actions
export default subscriptionSlice.reducer
