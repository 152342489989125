import { rootActions } from '@store/slices'
import { IEmailIntegratePayload } from '@store/slices/emails/integrateEmail'
import axios from '@utils/request'
import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'

interface TaskAction extends Action {
    payload: IEmailIntegratePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.emails.integrateEmail

function* integrateEmailSagaTask({ payload }) {
    try {
        const response = yield axios.post(`notifications/integrate-email/send-invitation`, { ...payload.data })

        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB) {
                payload?.sagaCB?.onSuccess(response.result)
            }
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* integrateEmailSaga(): any {
    yield takeLatest<TaskAction>(onRequest, integrateEmailSagaTask)
}

export default integrateEmailSaga
