import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

export interface IEditPreferenceCustomizationPayload extends ISagaCb {
    id: string
    data: {
        title: string
        payload?: string
        type?: string
        module: string
    }
}

export interface IListState {
    status: string
    data: any
    errors: any
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: {},
    errors: null
}

const editPreferenceCustomizationSlice = createSlice({
    name: 'hub/edit-preference-customization',
    initialState,
    reducers: {
        onRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editPreferenceCustomizationActions = editPreferenceCustomizationSlice.actions
export default editPreferenceCustomizationSlice.reducer
