import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface ISendInvitationPayload extends ISagaCb {
    data: {
        contact: string
        invitationType: 'Copy' | 'Send'
    }
}

export interface IState {
    status: string
    success: boolean
    result: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    success: true,
    result: {},
    errors: null
}

const sendInvitationSlice = createSlice({
    name: 'send-email/send-invitation',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ISendInvitationPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.result = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const sendInvitationActions = sendInvitationSlice.actions
export default sendInvitationSlice.reducer
